// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src589351801/src/oas-atlas-ui/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-js": () => import("/codebuild/output/src589351801/src/oas-atlas-ui/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-aplot-js": () => import("/codebuild/output/src589351801/src/oas-atlas-ui/src/pages/aplot.js" /* webpackChunkName: "component---src-pages-aplot-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src589351801/src/oas-atlas-ui/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profile-js": () => import("/codebuild/output/src589351801/src/oas-atlas-ui/src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */)
}

