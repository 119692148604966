import React from 'react'
import { withTheme } from 'styled-components'

import loadable from '@loadable/component'

const LazyPlot = loadable(() => import('react-plotly.js'), {
  fallback: (
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  ),
})

class FirstPlot extends React.Component {
  render() {
    return (
      <LazyPlot
        data={[
          {
            x: [1, 2, 3, 4, 5],
            y: [2, 6, 3, 4, 6],
            type: 'scatter',
            mode: 'lines+markers',
            marker: { color: 'red' },
          },
          { type: 'bar', x: [1, 2, 3], y: [2, 5, 3] },
        ]}
        layout={{ autosize: true, title: 'A Fancy Plot' }}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
      />
    )
  }
}

export default FirstPlot
