import { graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'
import Post from 'templates/post'
import Meta from 'components/meta'
import Layout from 'components/layout'
import FirstPlot from 'components/plots/firstplot'

const PlotContainer = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Meta site={get(data, 'site.meta')} />
      <div class="card shadow">
        <span class="card-title text-center">Hullo</span>
        <FirstPlot />
      </div>
    </Layout>
  )
}

export default PlotContainer
